import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import ServiceHeroMobile from "../components/service-hero-mobile";
import Navigation from "../components/navigation";

const AboutPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
						company
					}
				}
			}
		`
	);

	const companyName = data.site.siteMetadata.company;
	const title = `Thank you for contacting ${companyName}`;

	return (
		<Layout>
			<GatsbySeo title={title} language="en" noindex />
			<section>
				<Navigation thank />
				<Container>
					<Row className="py-5">
						<Col className="text-center">
							<div
								style={{
									borderTopLeftRadius: "6vh",
									borderBottomRightRadius: "6vh",
								}}
								className="bg-primary text-white  py-5"
							>
								<h1 className="pb-3">Thank you for contacting {companyName}</h1>
								<p>We will get back to you as soon as possible.</p>
								<Link className="link-white text-decoration-none" to="/">
									<p> &#8592; Home</p>
								</Link>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};
export default AboutPage;
