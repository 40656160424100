import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Link from "./link";
import Col from "react-bootstrap/Col";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { FaArrowRight } from "react-icons/fa";
import Navigation from "./navigation";
import Button from "react-bootstrap/Button";
import { GatsbyImage } from "gatsby-plugin-image";

const ServiceHeroMobile = ({
	title,
	subTitle,
	description,
	ctaText,
	eHeight,
	contact,
}) => {
	const emptyheight = eHeight || "120px";
	return (
		<div className="d-lg-none ">
			<Navigation />
			<Container className="position-relative">
				<Row>
					<Col className="pt-5 text-center ">
						<div className="mx-auto d-inline-block">
							<p className="text-primary cabin mb-0 pb-0 display-4 fw-bold">
								{title}
							</p>
							<hr className="bg-secondary mt-1 pt-0 mb-4 hr-big w-60  " />
						</div>
					</Col>
					<Col xs={12}>
						<div
							style={{
								borderTopLeftRadius: "6vh",
								borderBottomRightRadius: "6vh",
							}}
							className="bg-primary    text-white "
						>
							<Row className=" ">
								<Col className=" pt-5 px-5 " xl={6} lg={10}>
									<h2 className="">{subTitle}</h2>
									{contact !== true && <p>{description}</p>}
									{contact === true && { description }}
									{ctaText !== null && (
										<div
											style={{ height: emptyheight }}
											className="position-relative d-none d-md-block"
										>
											<div
												style={{
													background: "rgba(4, 114, 153, 0.45)",
													backdropFilter: "blur(48px)",
												}}
												className={`pt-3 pb-4 px-3 px-md-5  text-center translate-middle w-100 w-md-80 position-absolute top-100 start-50`}
											>
												<p className="text-white  fs-4">{ctaText}</p>
												<Button
													variant="outline-white"
													as={Link}
													to="/contact-us"
													className="w-100 w-md-auto  pb-2 cta-btn "
												>
													Contact
												</Button>
											</div>
										</div>
									)}
								</Col>
								{ctaText !== null && (
									<div
										style={{ height: emptyheight }}
										className="position-relative d-md-none"
									>
										<div
											style={{
												background: "rgba(4, 114, 153, 0.45)",
												backdropFilter: "blur(48px)",
											}}
											className={`pt-3 pb-4 px-3 px-md-5  text-center translate-middle w-100 w-md-80 position-relative top-100 start-50`}
										>
											<p className="text-white  fs-4">{ctaText}</p>
											<Button
												variant="outline-white"
												as={Link}
												to="/contact-us"
												className="w-100 w-md-auto  pb-2 cta-btn "
											>
												Contact
											</Button>
										</div>
									</div>
								)}
							</Row>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default ServiceHeroMobile;
